import CookieConsent from './_dist/cookie_consent.js'
import ahoy from 'ahoy.js'

function updateAnalytics() {
  ahoy.configure({
    visitsUrl: "/poll/visits",
    eventsUrl: "/poll/events",
    cookies: window.cc.acceptedCategories.indexOf('analytics') != -1,
    visitParams: {},
  });

  ahoy.trackView();
  ahoy.trackClicks("a, button, input[type=submit]");
  ahoy.trackSubmits("form");

  const domainGtag = document.getElementsByName("gtag")[0].content

  if (domainGtag) {
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());

    gtag('config', domainGtag);
  }
}


export default function() {
  if (document.querySelector("[data-tabs]")) new Tabby('[data-tabs]');

  if (!window.testEnvironment){
      window.CookieConsent = null
      CookieConsent()
      window.cc = new window.CookieConsent({
        title: "Wir verwenden Cookies 🍪",
        description: `Wir verwenden Cookies. Viele sind notwendig, um die Website und ihre Funktionen zu betreiben, andere sind für statistische oder Marketingzwecke. Mit der Entscheidung "Ablehnen" werden wir Ihre Privatsphäre respektieren und keine Cookies setzen, die nicht für den Betrieb der Seite notwendig sind.`,
        buttons: {
          acceptAll: "Alle akzeptieren",
          acceptSelected: "akzeptieren",
          reject: "Ablehnen",
          showSettings: "Cookie Einstellungen",
          hideSettings: "Schließen",
        },
        categories: {
          essentials: {
            label: "Essentiell",
            description: `Essenzielle Cookies ermöglichen grundlegende Funktionen und sind für die einwandfreie Funktion der Website erforderlich.`,
            checked: true,
            mandatory: true
          },
          analytics: {
            label: "Statistik",
            description: `Statistik-Cookies helfen Webseiten-Besitzern zu verstehen, wie Besucher mit Webseiten interagieren, indem Informationen anonym gesammelt und gemeldet werden.`,
            checked: true,
            mandatory: true
          }
        },
      })
    window.cc.on("change", (cc) => {
      updateAnalytics()
    })

    updateAnalytics()
  }
}
